import { Controller } from "@hotwired/stimulus"
import exifr from 'exifr'
import _ from 'lodash';

export default class extends Controller {
  static targets = [
    'select', 'current_location_pane', 'photo_pane',
    'code_pane', 'skip_pane', 'skip', 'latitude', 'longitude',
    'timestamp', 'verification_method', 'photo_upload', 'photo_select',
    'verification_code'
  ]
  static outlets = ['current-location']

  connect() {
    console.log(`method: ${this.verification_methodTarget.value}`);
    this.selectTarget.value = this.verification_methodTarget.value;

    this.selectTarget.addEventListener('change', (event) => {
      this.showPane(event.target.value);
    });

    this.skipTarget.addEventListener('change', (event) => {
      if (event.target.checked) {
        this.showPane('skip');
      }
      else {
        this.showPane(this.selectTarget.value);
      }
    });

    this.photo_selectTarget.addEventListener('change', this.handlePhotoSelected.bind(this));

    // default to verification method or first option
    this.selectTarget.value = this.verification_methodTarget.value || this.selectTarget.options[0].value;
    this.showPane(this.selectTarget.value);
  }

  showPane(value) {
    this.verification_methodTarget.value = value;
    //this.simpleMapOutlet.resetMap();

    // clear values
    this.latitudeTarget.value = null;
    this.longitudeTarget.value = null;
    this.timestampTarget.value = null;
    this.photo_uploadTarget.value = null;
    this.verification_codeTarget.value = null;


    this.current_location_paneTarget.classList.add('d-none');
    this.photo_paneTarget.classList.add('d-none');
    this.code_paneTarget.classList.add('d-none');
    this.skip_paneTarget.classList.add('d-none');

    switch(value) {
      case 'current_location':
        this.current_location_paneTarget.classList.remove('d-none');
        this.currentLocationOutlet.getLocation();
        //let controller = this.application.getControllerForElementAndIdentifier(this.current_location_paneTarget, 'log-verification--current-location')
        //controller ? controller.getLocation() : null;
        break;
      case 'photo':
        this.photo_paneTarget.classList.remove('d-none');
        break;
      case 'verification_code':
        this.code_paneTarget.classList.remove('d-none');
        break;
      case 'skip':
        this.skip_paneTarget.classList.remove('d-none');
        break;
    }
  }

  handleLocation({ detail: { content } }) {
    console.log('handleLocation');
    this.latitudeTarget.value = content.latitude;
    this.longitudeTarget.value = content.longitude;
    this.timestampTarget.value = this.convertToDateLocalString(new Date());

    //this.simpleMapOutlet.addMarker(content);

    console.log(`Latitude: ${this.latitudeTarget.value}, Longitude: ${this.longitudeTarget.value}, Logged on: ${this.timestampTarget.value}`);
  }

  async handlePhotoSelected(event) {
    this.photo_uploadTarget.files = event.target.files;
    let exif = await exifr.parse(event.target.files[0])

    // try to set the date to the earliest photo date
    let date = exif.DateTimeOriginal
    console.log(date)
    // if the date is valid, set the date input to the earliest date
    if (!isNaN(date)) {
      console.log(date)
      this.timestampTarget.value = this.convertToDateLocalString(date)
    } else {
      this.timestampTarget.value = ""
    }

    if (exif.latitude && exif.longitude) {
      this.latitudeTarget.value = exif.latitude
      this.longitudeTarget.value = exif.longitude

      //this.simpleMapOutlet.addMarker({latitude: exif.latitude, longitude: exif.longitude, content: `Photo location: ${exif.latitude}, ${exif.longitude}`})
    }

    // try to set the moment location to the average of all the photos
    // flat map the exif data to only include photos with GPS data
    //let points = exifs.flatMap(exif => { if (exif.latitude && exif.longitude) return [[exif.latitude, exif.longitude]]; else return [] })
    //console.log(points)
    //if (points.length > 0) {
      //let averagePoint = points.reduce((acc, point) => [acc[0] + point[0], acc[1] + point[1]], [0, 0]).map(coord => coord / points.length)
      //this.latitudeTarget.value = averagePoint[0]
      //this.longitudeTarget.value = averagePoint[1]
      //this.showMap(averagePoint[0], averagePoint[1])
    //} else {
      //this.latitudeTarget.value = ""
      //this.longitudeTarget.value = ""
      //this.hideMap()
    //}
  }

  convertToDateLocalString(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
}
