import { Controller } from "@hotwired/stimulus"
import _ from 'lodash';

export default class extends Controller {
  static values = { reference: String }
  static targets = ['verificationCode', 'timestamp', 'timestampContainer', 'submit', 'status', 'timestampStatus']

  connect() {
    this.verificationCodeTarget.addEventListener(
      'input',
      _.debounce(this.handleVerificationCodeInput.bind(this), 500)
    );
    console.log(this.referenceValue);

    let statusElement = document.createElement('div');
    statusElement.classList.add('mt-1', 'ms-1', 'fs-7', 'text-end');
    statusElement.setAttribute('data-code-verification-target', 'status');
    statusElement.innerHTML = '&nbsp;';
    this.verificationCodeTarget.insertAdjacentElement('afterend', statusElement);


    let timelineStatusElement = document.createElement('div');
    timelineStatusElement.classList.add('mt-1', 'ms-1', 'fs-7');
    timelineStatusElement.setAttribute('data-code-verification-target', 'timestampStatus');
    timelineStatusElement.innerHTML = '&nbsp;';
    this.timestampTarget.parentElement.insertAdjacentElement('afterend', timelineStatusElement);

    this.verifyCode(this.verificationCodeTarget.value);
  }

  setStatus(value) {
    if (this.statusTarget.innerHTML != value) {
      this.statusTarget.innerHTML = value;
    }
  }

  handleVerificationCodeInput(event) {
    this.setStatus('&nbsp;');
    this.submitTarget.disabled = true;
    this.submitTarget.value = 'Verify';
    this.timestampContainerTarget.classList.add('d-none');
    this.verifyCode(event.target.value);
  }

  verifyCode(code) {
    if (code.length > 5) {
      fetch(`/verification_codes/${code}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            this.handleResponse(data);
          });
        } else if (response.status == 404) {
          this.setStatus('<i class="fa-solid fa-circle-exclamation text-danger"></i> This code is not registered');
          this.timestampContainerTarget.classList.add('d-none');
        } else {
          this.setStatus('<i class="fa-solid fa-circle-exclamation text-danger"></i> Something went wrong - please try again');
          this.timestampContainerTarget.classList.add('d-none');
        }
      });
    }
  }

  handleResponse(data) {
    if (this.referenceValue == data.reference_id) {
      this.submitTarget.disabled = false;
      this.timestampContainerTarget.classList.remove('d-none');
      this.setStatus('<i class="fa-solid fa-circle-check text-success"></i> This code is valid');
      this.submitTarget.value = 'Create Log';

      if (data.timestamp) {
        this.timestampTarget.value = data.timestamp;
        this.timestampTarget.disabled = true;
        this.timestampStatusTarget.innerHTML = '<i class="fa-regular fa-circle-info"></i> Log date is set by the provided code';
        // change button text
      } else {
        this.timestampTarget.disabled = false;
        this.timestampStatusTarget.innerHTML = '<i class="fa-regular fa-circle-info"></i> Please provide the date for this log entry';
      }
    } else {
      this.setStatus('<i class="fa-solid fa-circle-exclamation text-danger"></i> This code is invalid');
      this.timestampContainerTarget.classList.add('d-none');
    }
  }
}
