import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container"]
  static values = { url: String }

  connect() {
    super.connect();
    console.log(`remote-html-connected: ${this.urlValue}`);
    fetch(this.urlValue)
      .then(response => response.text())
      .then(body => {
        this.containerTarget.innerHTML = body;
      }
    );
  };
}
