import { Controller } from "@hotwired/stimulus"
import L from "leaflet"
import { GestureHandling } from "leaflet-gesture-handling";

export default class extends Controller {
  static targets = ["container"]
  static values = { geoJson: String, lat: Number, lon: Number }

  connect() {
    this.showMap();
    console.log(`Lat: ${this.latValue}`);
    console.log(`Lon: ${this.lonValue}`);
  };

  disconnect() {
    if (this.map) {
      this.map.remove();
    }
  }

  showMap() {
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

    var map = L.map(this.containerTarget, {gestureHandling: true});

    L.control.scale({'imperial': true, 'metric': false}).addTo(map);

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(map);

    //L.tileLayer('https://api.maptiler.com/maps/outdoor-v2/{z}/{x}/{y}@2x.png?key=IyXxRCm1xp38PTNOsOT8', {
      //maxZoom: 19,
      //attribution: "\u003ca href=\"https://www.maptiler.com/copyright/\" target=\"_blank\"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e"
    //}).addTo(map);

    if (this.latValue && this.lonValue) {
      L.marker([this.latValue, this.lonValue]).addTo(map)
        .bindPopup(`Latitude: ${this.latValue}<br>Longitude: ${this.lonValue}`);
      if (!this.geoJsonValue) {
        map.setView([this.latValue, this.lonValue], 12);
      }
    }

    if (this.geoJsonValue) {
      let geoJson = JSON.parse(this.geoJsonValue);
      let coordinates = geoJson.geometry.coordinates[0];
      let geoJsonFeature = L.geoJSON(geoJson).addTo(map);
      geoJsonFeature.addTo(map);

      if (this.latValue && this.lonValue) {
        map.fitBounds(geoJsonFeature.getBounds().extend([this.latValue, this.lonValue]));
      }
      else {
        map.fitBounds(geoJsonFeature.getBounds());
      }
    }
  };

  getLocation() {
    let controller = this;

    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 60000
    };
    function success(geo) {
      controller.showMap(geo.coords.latitude, geo.coords.longitude);
    };
    function failure(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      if (err.code == 1) {
        alert("Permission to determine your current location was denied. Please allow location access and try again.");
      } else if (err.code == 2) {
        alert("Your location could not be determined. Please try again or use an alternate method.");
      } else if (err.code == 3) {
        alert("Your location could not be determined. Please try again.");
      }
    };

    navigator.geolocation.getCurrentPosition(success, failure, options);
  };
}

// <div data-controller="map" data-map-target="container" data-map-zoom-value="5" data-map-center-lat="39.8283" data-map-center-lng="-98.5795" data-map-id="trails-map" class="map mb-3" style="height: 500px;"></div>

