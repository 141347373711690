import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper/bundle';

export default class extends Controller {
  connect() {
    this.swiper = new Swiper(this.element, {
      direction: 'horizontal',
      loop: true,
      autoplay: {
        delay: 0,
        disableOnInteraction: false
      },
      freeMode: true,
      speed: 7000,
      centerInsufficientSlides: true,
      breakpoints: {
        0: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 6,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 8,
          spaceBetween: 10,
        },
        1280: {
          slidesPerView: 10,
          spaceBetween: 10,
        }
      }
    });
  }
}
