import { Modal } from "bootstrap"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    console.log("modal_shim_controller connected");
    this.modal = new Modal(this.element);
    let backdrop = document.querySelector(".modal-backdrop");

    if (backdrop) {
      console.log("backdrop found");
      document.body.style.removeProperty("overflow");
      backdrop.remove();
      this.modal.show();
    }
  }

}
