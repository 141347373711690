import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["icon", "spinner"]
  static values = { year: String }

  addToCart({ detail: { content } }) {
    this.dispatch("addToCart", { detail: { content: { productId: content.productId, year: this.yearValue } } })
    this.iconTarget.classList.add('d-none');
    this.spinnerTarget.classList.remove('d-none');

    setTimeout(()=>{
      this.iconTarget.classList.remove('d-none');
      this.spinnerTarget.classList.add('d-none');
    }, 1000);
  };
}
