import { Controller } from "@hotwired/stimulus"
import lightGallery from "lightgallery"
import lgThumbnail from "lightgallery/plugins/thumbnail"
import lgZoom from "lightgallery/plugins/zoom"

export default class extends Controller {
  static values = { selector: String }

  connect() {
    lightGallery(this.element, {
      speed: 500,
      selector: this.selectorValue || 'a',
      mobileSettings: {
        controls: false,
        showCloseIcon: true,
        download: true
      },
    });
  }

  // Disconnect lightGallery when the controller is disconnected
  disconnect() {
    lightGallery(this.element).destroy();
  }
}
