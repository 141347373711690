import { Controller } from "@hotwired/stimulus"
import _ from 'lodash';

export default class extends Controller {
  static values = { status: { type: String, default: "Searching..." } }
  static targets = ['latitude', 'longitude', 'latitudeDisplay', 'longitudeDisplay', 'timestamp', 'timestampDisplay', 'status', 'submit']
  static outlets = ['simple-map']

  connect() {
    this.getLocation();
  }

  statusValueChanged() {
    this.statusTarget.innerHTML = this.statusValue;
  }

  getLocation() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.latitudeDisplayTarget.innerHTML = position.coords.latitude
        this.latitudeTarget.value = position.coords.latitude

        this.longitudeDisplayTarget.innerHTML = position.coords.longitude
        this.longitudeTarget.value = position.coords.longitude

        this.timestampDisplayTarget.innerHTML = new Date(position.timestamp).toLocaleString()

        this.simpleMapOutlet.addMarker({ latitude: position.coords.latitude, longitude: position.coords.longitude, content: `Current location: ${position.coords.latitude}, ${position.coords.longitude}` });

        this.statusValue = "<i class='fa-solid fa-circle-check text-success'></i> Obtained";
        this.submitTarget.disabled = false;
        //this.dispatch("location", { detail: { content: { latitude: position.coords.latitude, longitude: position.coords.longitude } } });
      },
      (error) => {
        this.statusValue = "<i class='fa-solid fa-circle-exclamation text-danger'></i> Unable to obtain location";
      },
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
    );
  }
}
