import { Controller } from "@hotwired/stimulus"
import lightGallery from "lightgallery"
import lgThumbnail from "lightgallery/plugins/thumbnail"
import lgZoom from "lightgallery/plugins/zoom"

export default class extends Controller {
  connect() {
    this.gridzy = new Gridzy(this.element);
  }

  // Disconnect lightGallery when the controller is disconnected
  disconnect() {
    if (this.gridzy) {
      this.gridzy.destroy();
    }
  }
}
