import { Controller } from "@hotwired/stimulus"
import L from "leaflet"
import { GestureHandling } from "leaflet-gesture-handling"
import 'leaflet.markercluster'
import * as Handlebars from 'handlebars';

const popupTemplate = `
  <div class="d-flex flex-column">
    <span class="fw-bolder mb-1">{{log.activity_name}}</span>
    <small class="text-muted">{{log.checked_in_on}}</small>
    <a href="{{log.url}}" data-turbo="false" target="_blank" class="mt-1 text-decoration-none">View Details <i class="bi bi-box-arrow-up-right"></i></a>
  </div>
`

export default class extends Controller {
  static targets = ["container"]
  static values = { logs: String, gestureHandling: Boolean }

  connect() {
    this.showMap();
  };

  disconnect() {
    if (this.map) {
      this.map.remove();
    }
  }

  showMap() {
    if (this.gestureHandlingValue == true) {
      L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
      var map = L.map(this.containerTarget, {gestureHandling: true});
    }
    else {
      var map = L.map(this.containerTarget);
    }

    L.control.scale({'imperial': true, 'metric': false}).addTo(map);

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 15,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(map);

    let jsonLogs = JSON.parse(this.logsValue);
    let bounds = [];

    var markers = L.markerClusterGroup();
    jsonLogs.forEach(function (log) {
      markers.addLayer(L.marker([log.lat, log.lng])
        .bindPopup(Handlebars.compile(popupTemplate)({log: log})));
      bounds.push([log.lat, log.lng]);
    });
    map.addLayer(markers);
    map.fitBounds(bounds);
  };

  getLocation() {
    let controller = this;

    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 60000
    };
    function success(geo) {
      controller.showMap(geo.coords.latitude, geo.coords.longitude);
    };
    function failure(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      if (err.code == 1) {
        alert("Permission to determine your current location was denied. Please allow location access and try again.");
      } else if (err.code == 2) {
        alert("Your location could not be determined. Please try again or use an alternate method.");
      } else if (err.code == 3) {
        alert("Your location could not be determined. Please try again.");
      }
    };

    navigator.geolocation.getCurrentPosition(success, failure, options);
  };
}

// <div data-controller="map" data-map-target="container" data-map-zoom-value="5" data-map-center-lat="39.8283" data-map-center-lng="-98.5795" data-map-id="trails-map" class="map mb-3" style="height: 500px;"></div>

