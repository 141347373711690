import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form", "input", "status", "type" ]

  processInput(event) {
    this.showSearching();
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    let currentSearchTerm = this.inputTarget.value;

    if (currentSearchTerm.length == 0 || currentSearchTerm.length > 2) {
      this.timeout = setTimeout(() => {
        this.formTarget.requestSubmit();
        this.hideSearching();
      }, 500);
    } else {
      this.hideSearching();
    }
  };

  filterByType(event) {
    if (event.target.id == this.typeTarget.value) {
      this.typeTarget.value = null;
    } else {
      this.typeTarget.value = event.target.id;
    }
    this.processInput(event);
  }

  showSearching() {
    this.statusTarget.classList.remove("d-none");
  };

  hideSearching() {
    this.statusTarget.classList.add("d-none");
  }

  submitForm(event) {
    event.target.form.requestSubmit();
  }
}

