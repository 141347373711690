import { Controller } from "@hotwired/stimulus"
import exifr from 'exifr'
import L from 'leaflet'

export default class extends Controller {
  static values = { layout: String, freeMode: Boolean }
  static targets = ["fileInput", "dateInput", "loggedAt", "name", "submit", "location", "latitude", "longitude"]

  connect() {
    this.fileInputTarget.addEventListener('change', this.handleFileChange.bind(this))
    this.map = L.map(document.getElementById("map"));
    var outdoors = L.tileLayer('https://api.mapbox.com/styles/v1/allroadstaken/clzbxv6l3002l01p66v9eb66s/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYWxscm9hZHN0YWtlbiIsImEiOiJjbDk3azZyZnYwY29mM3BsOW1mdjE2ZDRkIn0.Dlg5NsF2Y08HVZ6vVn4qQQ', {
      maxZoom: 19,
      minZoom: 1,
      attribution: '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    })
    outdoors.addTo(this.map);
    this.markers = [];
  }

  handleFileChange(event) {
    const files = Array.prototype.slice.call(event.target.files)
    // add the images as thumbnails under the form
    this.addThumbnails(files)
    this.setFormFromExif(files)
    this.setVisibilities()
  }

  setVisibilities() {
    // make date and name fields visible after files are selected
    if (this.fileInputTarget.files.length > 0) {
      this.loggedAtTarget.classList.remove("d-none")
      this.locationTarget.classList.remove("d-none")
      this.nameTarget.classList.remove("d-none")
      this.submitTarget.classList.remove("d-none")
    } else {
      this.loggedAtTarget.classList.add("d-none")
      this.locationTarget.classList.add("d-none")
      this.nameTarget.classList.add("d-none")
      this.submitTarget.classList.add("d-none")
    }
  }

  async setFormFromExif(files) {
    let exifs = await Promise.all(files.map(file => exifr.parse(file)))

    // try to set the date to the earliest photo date
    let dates = exifs.map(exif => exif.DateTimeOriginal)
    console.log(dates)
    let earliestDate = new Date(Math.min(...dates))
    // if the date is valid, set the date input to the earliest date
    if (!isNaN(earliestDate)) {
      console.log(earliestDate)
      this.dateInputTarget.value = this.convertToDateTimeLocalString(earliestDate)
    } else {
      this.dateInputTarget.value = ""
    }

    // try to set the moment location to the average of all the photos
    // flat map the exif data to only include photos with GPS data
    let points = exifs.flatMap(exif => { if (exif.latitude && exif.longitude) return [[exif.latitude, exif.longitude]]; else return [] })
    console.log(points)
    if (points.length > 0) {
      let averagePoint = points.reduce((acc, point) => [acc[0] + point[0], acc[1] + point[1]], [0, 0]).map(coord => coord / points.length)
      this.latitudeTarget.value = averagePoint[0]
      this.longitudeTarget.value = averagePoint[1]
      this.showMap(averagePoint[0], averagePoint[1])
    } else {
      this.latitudeTarget.value = ""
      this.longitudeTarget.value = ""
      this.hideMap()
    }
  }


  convertToDateTimeLocalString(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  addThumbnails(files) {
    document.getElementById("selectedFiles").innerHTML = "";

    files.forEach(file => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = document.createElement("img");
        img.src = e.target.result;
        img.style.objectFit = "cover";
        img.style.height = "50px";
        img.classList.add("img-thumbnail", "img-fluid", "overflow-hidden");
        // add to selectedFiles div
        document.getElementById("selectedFiles").appendChild(img);
      }
      reader.readAsDataURL(file);
    })
  }

  getLocation() {
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 60000
    };
    function success(geo) {
      this.showMap(geo.coords.latitude, geo.coords.longitude);
    };
    function failure(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      if (err.code == 1) {
        alert("Permission to determine your current location was denied. Please allow location access and try again.");
      } else if (err.code == 2) {
        alert("Your location could not be determined. Please try again or use an alternate method.");
      } else if (err.code == 3) {
        alert("Your location could not be determined. Please try again.");
      }
    };

    navigator.geolocation.getCurrentPosition(success, failure, options);
  };

  showMap(lat, lon) {
    // clear existing markers
    this.markers.forEach(marker => {
      this.map.removeLayer(marker);
    });
    this.markers = [];
    var marker = L.marker([lat, lon],
      {
        draggable: true,
        autoPan: true
      }
    ).addTo(this.map);
    marker.on('dragend', (event) => {
      this.latitudeTarget.value = event.target.getLatLng().lat;
      this.longitudeTarget.value = event.target.getLatLng().lng;
    });
    this.markers.push(marker);
    this.map.setView([lat, lon], 8);
  }

  hideMap() {
    var map = this.map
    // remove markers from map
    map.eachLayer(function (layer) {
      // remove only if it's a marker layer
      if (layer instanceof L.Marker) {
        map.removeLayer(layer);
      }
    });
  }
}
