import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["count"]

  setQuantity(quantity) {
    this.countTarget.innerHTML = quantity || 0;
    if (quantity && quantity > 0) {
      this.countTarget.classList.remove('d-none');
    }
    else {
      this.countTarget.classList.add('d-none');
    }
  };
}
