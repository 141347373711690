window.ARTLocationHelper = function () {
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 60000
  };
  function success(geo) {
    var latq = "lat=" + geo.coords.latitude;
    var lonq = "lon=" + geo.coords.longitude;

    var date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    document.forms["current_location"]["check_in_latitude"].value = geo.coords.latitude;
    document.forms["current_location"]["check_in_longitude"].value = geo.coords.longitude;

    document.forms["current_location"]["check_in_checked_in_on"].value = `${year}-${month}-${day}`;
    document.forms["current_location"].requestSubmit();
  };
  function failure(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
    if (err.code == 1) {
      alert("Permission to determine your current location was denied. Please allow location access and try again.");
    } else if (err.code == 2) {
      alert("Your location could not be determined. Please try again or use an alternate method.");
    } else if (err.code == 3) {
      alert("Your location could not be determined. Please try again.");
    }
  };
  function getLocation() {
    navigator.geolocation.getCurrentPosition(success, failure, options);
  };

  function handleLocationPhotoSelect(evt) {
    var file = evt.target.files[0];

    var reader = new FileReader();
    reader.onload = function(e) {
      try {
        var exifObj = piexif.load(e.target.result);

        var raw_lat = exifObj["GPS"][piexif.GPSIFD.GPSLatitude];
        var raw_lon = exifObj["GPS"][piexif.GPSIFD.GPSLongitude];
        console.log(`LAT: ${raw_lat}`);
        console.log(`LON: ${raw_lon}`);

        var date = exifObj["Exif"][piexif.ExifIFD.DateTimeOriginal].split(" ")[0].replace(/:/g, "-");
        var lat = piexif.GPSHelper.dmsRationalToDeg(exifObj["GPS"][piexif.GPSIFD.GPSLatitude], exifObj["GPS"][piexif.GPSIFD.GPSLatitudeRef]);
        var lon = piexif.GPSHelper.dmsRationalToDeg(exifObj["GPS"][piexif.GPSIFD.GPSLongitude], exifObj["GPS"][piexif.GPSIFD.GPSLongitudeRef]);
        console.log(`LAT: ${lat}`);
        console.log(`LON: ${lon}`);

        var latq = "lat=" + lat;
        var lonq = "lon=" + lon;
        let dateq = `date=${date}`;

        let urlParts = window.location.href.split("?");
        let queryString = [urlParts[1], latq, lonq, dateq].filter(Boolean).join("&");
        console.log(`queryString: ${queryString}`);

        window.location.href = urlParts[0] + "?" + queryString;
      }
      catch (err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
        alert("Location information could not be found. Please try a different photo.");
      }
    };
    reader.readAsDataURL(file);
  }

  return {
    handleLocationPhotoSelect: handleLocationPhotoSelect,
    getLocation: getLocation,
    url: null
  }
}();
