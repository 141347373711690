import { Controller } from "@hotwired/stimulus"
import L from "leaflet"
import { GestureHandling } from "leaflet-gesture-handling"

export default class extends Controller {
  static targets = ["container", "latitude", "longitude"]
  static values = { location: Array, geometry: String }

  connect() {
    this.map = this.showMap();
    this.addedMarkers = [];
  };

  disconnect() {
    if (this.map) {
      this.map.remove();
    }
  }

  refreshMap() {
    if (this.map) {
      this.map.invalidateSize();
    }
  }

  showMap() {
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
    var map = L.map(this.containerTarget, {
      gestureHandling: true,
      zoomControl: false
    });

    var outdoors = L.tileLayer('https://api.mapbox.com/styles/v1/allroadstaken/clzbxv6l3002l01p66v9eb66s/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYWxscm9hZHN0YWtlbiIsImEiOiJjbDk3azZyZnYwY29mM3BsOW1mdjE2ZDRkIn0.Dlg5NsF2Y08HVZ6vVn4qQQ', {
      maxZoom: 19,
      minZoom: 1,
      attribution: '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    })

    map.addLayer(outdoors);

    var bounds = [];

    if (this.locationValue && this.locationValue.length > 0) {
      this.addMarker({
        latitude: this.locationValue[0],
        longitude: this.locationValue[1],
        content: `Log location: ${this.locationValue[0]}, ${this.locationValue[1]}`
      });
    }

    if (this.geometryValue) {
      let jsonGeometry = JSON.parse(this.geometryValue);
      L.geoJSON(jsonGeometry).addTo(map);
      bounds.push(L.geoJSON(jsonGeometry).getBounds());
    }

    if (bounds.length > 0) {
      map.fitBounds(bounds);
    }
    this.bounds = bounds;

    return map;
  };

  addMarker({ latitude, longitude, content }) {
    let marker = L.marker([latitude, longitude]).addTo(this.map)
    this.addedMarkers.push(marker);
    if (content) {
      marker.bindPopup(content);
    }
    let bounds = this.map.getBounds();
    bounds.extend([latitude, longitude]);
    this.map.fitBounds(bounds);
  }

  resetMap() {
    // remove all markers
    _.each(this.addedMarkers, function (marker) {
      this.map.removeLayer(marker);
    }.bind(this));
    this.map.invalidateSize();
    this.map.fitBounds(this.bounds);
  }





  getAndSetLocation() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        L.marker([position.coords.latitude, position.coords.longitude]).addTo(this.map)
        // get bounds of current map layer
        let bounds = this.map.getBounds();
        bounds.extend([position.coords.latitude, position.coords.longitude]);
        this.map.fitBounds(bounds);

        //this.latitudeTarget.value = position.coords.latitude;
        //this.longitudeTarget.value = position.coords.longitude

        this.dispatch("location", { detail: { content: { latitude: position.coords.latitude, longitude: position.coords.longitude } } });
      },
      (error) => {
        console.error(`Error: ${error}`);
      },
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
    );
  }
}
// <div data-controller="map" data-map-target="container" data-map-zoom-value="5" data-map-center-lat="39.8283" data-map-center-lng="-98.5795" data-map-id="trails-map" class="map mb-3" style="height: 500px;"></div>

