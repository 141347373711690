import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = [ "input", "results" ]
  static values = { searchUrl: String }

  connect() {
    this.resultsTarget.innerHTML = '';
  }

  processInput(event) {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    if (this.blurTimeout) {
      clearTimeout(this.blurTimeout)
    }
    let currentSearchTerm = this.inputTarget.value;

    if (currentSearchTerm.length > 2) {
      this.timeout = setTimeout(() => {
        let url = new URL(this.searchUrlValue);
        url.searchParams.append("q", this.inputTarget.value);

        fetch(url)
          .then(response => response.text())
          .then(body => {
            this.resultsTarget.innerHTML = body;
          }
        );
      }, 500);
    }
  };

  onblur(event) {
    this.blurTimeout = setTimeout(() => {
      this.resultsTarget.innerHTML = '';
    }, 500);
  };
}
