import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["quantity"]
  static values = { lineId: String, year: String, quantity: Number }

  connect() {
    this.quantityTarget.addEventListener("blur", this.handleQuantity.bind(this));
    this.quantityTarget.addEventListener("click", event => event.target.focus());
  }

  handleQuantity(event){
    this.dispatch("updateQuantity", { detail: { content: { id: this.lineIdValue, quantity: this.quantityTarget.value, year: this.yearValue } } })
  }

  remove(event){
    this.quantityTarget.value = 0;
    this.dispatch("updateQuantity", { detail: { content: { id: this.lineIdValue, quantity: 0, year: this.yearValue } } })
  }
}
