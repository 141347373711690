import { Controller } from "@hotwired/stimulus"
import exifr from 'exifr'
import _ from 'lodash';

export default class extends Controller {
  static values = { status: { type: String, default: "No photo selected"} }
  static targets = ['latitude', 'longitude', 'latitudeDisplay', 'longitudeDisplay', 'timestamp', 'timestampDisplay', 'status', 'submit', 'input']
  static outlets = ['simple-map']

  connect() {
    this.inputTarget.addEventListener('change', this.handlePhotoSelected.bind(this));
  }

  async handlePhotoSelected(event) {
    this.submitTarget.disabled = true;
    this.simpleMapOutlet.resetMap();

    let exif = await exifr.parse(event.target.files[0])

    // try to set the date to the earliest photo date
    let date = exif.DateTimeOriginal
    // if the date is valid, set the date input to the earliest date
    if (!isNaN(date)) {
      this.timestampTarget.value = this.convertToDateLocalString(date)
      this.timestampDisplayTarget.innerHTML = new Date(date).toLocaleDateString()
    } else {
      this.timestampTarget.value = null;
      this.timestampDisplayTarget.innerHTML = "N/A";
    }

    if (exif.latitude && exif.longitude) {
      this.latitudeTarget.value = exif.latitude
      this.latitudeDisplayTarget.innerHTML = exif.latitude.toFixed(5)
      this.longitudeTarget.value = exif.longitude
      this.longitudeDisplayTarget.innerHTML = exif.longitude.toFixed(5)

      this.statusValue = '<i class="fa-solid fa-circle-check text-success"></i> Location Found';

      this.simpleMapOutlet.addMarker({latitude: exif.latitude, longitude: exif.longitude, content: `Photo location: ${exif.latitude}, ${exif.longitude}`})
      this.submitTarget.disabled = false;
    } else {
      this.statusValue = '<div class="alert alert-warning my-0 fs-8" style="padding: 2px 5px"><i class="fa-solid fa-circle-exclamation text-warning"></i> No location found in the selected photo</div>';
      this.submitTarget.disabled = true;
      this.latitudeTarget.value = null;
      this.longitudeTarget.value = null;
      this.latitudeDisplayTarget.innerHTML = "N/A";
      this.longitudeDisplayTarget.innerHTML = "N/A";
    }
  }

  convertToDateLocalString(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  statusValueChanged() {
    this.statusTarget.innerHTML = this.statusValue;
  }
}
