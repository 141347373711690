import { Controller } from "@hotwired/stimulus"

const LEAVING_PAGE_MESSAGE = "You have attempted to leave this page and you are uploading files. If you leave, you will need to reupload these files.  Are you sure you want to exit this page?"

export default class extends Controller {
  static values = { autoSubmit: Boolean }
  static targets = [ "status" ]

  startDirectUploads(event) {
    this.setUploading("true")
    this.statusTarget.innerHTML = "Uploading 0%";
  }

  endDirectUploads(event) {
    this.setUploading("false")
  }

  validate(event) {
    let tooBig = _.some(event.target.files, (file) => {
      return (file.size / 1024.0 / 1024.0) > 10
    });
    if (tooBig) {
      this.statusTarget.innerHTML = '<div class="alert alert-danger"><strong>Photo/s must be under 15mb.</strong><br/>Please select an alternate photo or use an app to resize.</div>';
    } else {
      console.log(`Auto submit value: ${this.autoSubmitValue}`);
      if (!!this.autoSubmitValue) {
        event.target.form.requestSubmit();
      }
    }
  }

  directUploadProgress(event) {
    this.statusTarget.innerHTML = `Uploading ${Math.floor(event.detail.progress)}%`;
  }

  leavingPage(event) {
    if (this.isUploadingFiles()) {
      if (event.type == "turbo:before-visit") {
        if (!window.confirm(LEAVING_PAGE_MESSAGE)) {
          event.preventDefault()
        }
      } else {
        event.returnValue = LEAVING_PAGE_MESSAGE;
        return event.returnValue;
      }
    }
  }

  setUploading(changed) {
    this.data.set("uploading", changed)
  }

  isUploadingFiles() {
    return this.data.get("uploading") == "true";
  }
}
