import { Offcanvas } from "bootstrap"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.offcanvas = Offcanvas.getOrCreateInstance(this.element);
    this.backdrop = document.querySelector(".offcanvas-backdrop");

    if (this.backdrop) {
      this.backdrop.addEventListener("click", () => {
        this.element.classList.remove("show");
        this.backdrop.remove();
        document.body.style.removeProperty("overflow");
      });

      this.closeButton = document.querySelector("#offCanvasClose");
      this.closeButton.addEventListener("click", () => {
        this.element.classList.remove("show");
        this.backdrop.remove();
        document.body.style.removeProperty("overflow");
      });
    }
  }
}
