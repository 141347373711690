import { Controller } from "@hotwired/stimulus"
import ColorThief from "colorthief"


export default class extends Controller {
  connect() {
    this.colorThief = new ColorThief()
    this.image = this.element
    this.image.addEventListener("load", this.updateColor.bind(this))
  }

  updateColor() {
    try {
      let color = this.colorThief.getColor(this.image)
      this.element.parentElement.style.backgroundColor = `rgb(${color[0]}, ${color[1]}, ${color[2]})`
    } catch (e) {
      console.log(e)
    }
  }
}
