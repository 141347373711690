import { Controller } from "@hotwired/stimulus"
import _ from 'lodash';
import autoComplete from "@tarekraafat/autocomplete.js";

export default class extends Controller {
  static values = { url: String }
  static targets = ['searchInput', 'type', 'id', 'name']

  connect() {
    // attached to user input changed
    let config = {
      selector: () => this.searchInputTarget,
      placeHolder: "Search for place...",
      data: {
        //remote data source
        src: async () => {
          // get the data from the remote source
          return await this.getData();
        },
        keys: ["name"],
      },
      resultItem: {
        highlight: false,
      },
      debounce: 500,

    }
    this.autocomplete = new autoComplete(config);

    var handleSelection = (event) => {
      const feedback = event.detail;
      this.autocomplete.input.blur();
      // Prepare User's Selected Value
      this.idTarget.value = feedback.selection.value.activity_id;
      this.typeTarget.value = feedback.selection.value.activity_type;
      this.nameTarget.value = feedback.selection.value.name;
      // Render selected choice to selection div
      // Replace Input value with the selected value
      this.autocomplete.input.value = feedback.selection.value[feedback.selection.key];
      // Console log autoComplete data feedback
      this.element.requestSubmit();
    }
    this.autocomplete.input.addEventListener("selection", handleSelection.bind(this));
  }

  searchNearby() {
    // get the user's current location
    navigator.geolocation.getCurrentPosition((position) => {
      fetch(`/search_sources/check_in_activities?filter[relative_to]=[${position.coords.latitude},${position.coords.longitude}]&page[size]=10`)
        .then(response => response.json())
        .then(data => { console.log(data); });
    });
  }

  setVisibility() {
    if (this.idTarget.value) {
      this.searchInputTarget.classList.add("d-none");
    } else {
      this.searchInputTarget.classList.remove("d-none");
    }
  }

  async getData() {
    // urlencode the input value
    let url = `${this.urlValue}?q[name_cont]=${this.searchInputTarget.value}`;
    console.log(`fetching data from ${url}`);

    let response = await fetch(url)
    return response.json();
  }

  renderData(data) {
    console.log(data);
  }
}
