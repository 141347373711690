import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

export default class extends Controller {
  redirect(event) {
    console.log(`form-redirect-controlller: ${event.detail.fetchResponse.response}`)
    if (event.detail.success) {
      console.log(`redirecting to ${event.detail.fetchResponse.response.url}`)
      const fetchResponse = event.detail.fetchResponse

      history.pushState(
        { turbo_frame_history: true },
        "",
        fetchResponse.response.url
      )

      Turbo.visit(fetchResponse.response.url)
    }
  }

  handleMissingFrame(event) {
    console.log(`form-redirect-controlller: missing-frame ${event.detail}`)
  }
}
