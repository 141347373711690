import { Controller } from "@hotwired/stimulus"
import ClipboardJS from 'clipboard'
import * as bootstrap from 'bootstrap'


export default class extends Controller {
  connect() {
    // add event listener to element for on click event
    console.log("connected to share content controller")
    // set data attribute on element to current URL
    var button = this.element
    button.setAttribute("data-clipboard-text", window.location.href)
    var clipboard = new ClipboardJS(this.element)

    var tooltip = bootstrap.Tooltip.getOrCreateInstance('#' + this.element.id)
    console.log(tooltip)

    clipboard.on('success', function(e) {
      console.log(e)
      tooltip.setContent({ '.tooltip-inner': 'Copied!' })
      e.clearSelection()
      setTimeout(function() {
        tooltip.setContent({ '.tooltip-inner': 'Copy link to clipboard' })
      }, 5000);
    })
  }
}
