import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  loadStats(event) {
    console.log("StatsModalController#loadStats");

    let stats_url = event.relatedTarget.getAttribute("data-stats-url");

    fetch(stats_url)
      .then(response => response.text())
      .then(html => {
        this.element.querySelector(".modal-content").innerHTML = html;
      }
    )
  }
}
